@media (min-width: 767px) {
    .changeWidthScroll ::-webkit-scrollbar {
        width: 22px !important;
    }
    .rbc-calendar .rbc-toolbar {
        position: relative;
        height: 33px;
    }
    .rbc-calendar .rbc-toolbar .rbc-btn-group {
        position: absolute;
        top: 0;
        left: 0;
    }
    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        height: 33px;
        line-height: 33px;
    }
}
@media (max-width: 766px) {
    .working-value-update {
        float: right;
        width: 100%;
    }
    .rbc-event-content p {
        font-size: 10px;
    }
}
.calendar-checkin-work-time-log {
    color: red;
    float: left;
    margin-top: 2rem !important;
}
.calendar-item-update-time {
    float: left;
    margin-left: 5% !important;
}