div[class^="withRouter-Main--content"] {
  position: relative;
  z-index: 1;
}

@media print {  
  a:after {
    content: "";
  }
  
  a[href]:after {
    content: none !important;
  }

  table {
    page-break-after: always;
  }

  @page {
    margin: 0;
    size: A4 landscape;
    page-break-after: always;
  }
}


