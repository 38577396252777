div[class^="withRouter-Main--content"] {
  position: relative;
  z-index: 1;
}

@media print {
  a:after { content:''; }
  a[href]:after { content: none !important; }

  html {
    margin: 0;
    transform: scale(.5);
    margin-right: -100%;
    height: 100%;
    transform-origin: 0 0;
  }
  table {page-break-inside: avoid;}
  @page {
    margin: 0;
    size: A4 landscape;
    page-break-after: always;
  }
}

.page-a4 {
  page-break-after: always;
  display: block;
  margin: auto;
  /*size: A4 landscape;*/
  padding: 3rem;
  background: #fff;
  box-sizing: border-box;
}

.page-a4.pdf table {
  font-family: "Michi";
  font-weight: bold;
}

table.pdf, table.print {
  border: 1.4px solid #000000;
  border-collapse: unset;
  width: 100%;
  border-spacing:0
}

table.pdf td, table.print td {
  border: .7px solid #000000;
  text-align: center;
  font-size: 13px;
}
table.pdf.table2 tr td {
  height: 36px !important;
  max-height: 36px !important;
}
table.print.table2 tr td {
  height: 30px !important;
  max-height: 30px !important;
}
.construction-tb.pdf {
  margin-top: 26px;
}
.construction-tb.print {
  margin-top: 28px;
}
table.pdf tr.row2 td {
  padding: 50px;
}
table.pdf tr.row1 td {
  padding: 10px 0px;
}

table.table2 tr td {
  padding: 0px 5px;
}

.report-basic-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.report-title span {
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.report-date, .report-worker-name, .report-car-number {
  border-bottom: 1px solid #ccc;
}

.report-worker-name {
  display: flex;
  justify-content: space-between;
}
.report-worker-name span.seal {
  margin-right: 2rem;
}

.report-stamp {
  width: 100%;
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.report-stamp img {
  width: 100px;
  height: 100px;
}