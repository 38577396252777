#CheckInBtn  > span{
    display: inline-block;
}
#CheckOutBtn > span{
    display: inline-block;
}

.checkin-grid, .checkout-grid {
  position: relative;
}

.checkin-grid svg, .checkout-grid svg {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}
