.report-smartphone {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.report-smartphone .report-item {
  padding: 10px;
  border-top: 1px solid #dedede;
  border-left: none;
  border-right: none;
}

.report-smartphone .report-item:nth-child(even) {background: #f2f2f2}
