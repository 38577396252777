.monthly-report-head {
  margin: -23px;
  margin-bottom: 1rem;
  background: #fff;
  padding-bottom: 1px;
  position: sticky;
  top: 65px;
  z-index: 999;
  box-shadow: -1px 1px 1px #ccc;
}
.pdf-container {
  display: none;
  position: relative;
}
