/* .printTable {
    border: 0.1px solid;
    border-collapse: separate;
  }
  .printTh, .printTr {
    border: 0.1px solid;
    border-collapse: collapse;
  } */
  /* thead, tbody {
    margin-left: 16px;
    margin-right: 16px
  } */
  
   @page {
    size: 'A4';
    margin: 5mm 5mm 5mm 5mm;
  }

  @media print {
    table {
      border-collapse: separate; /* the default option */
      border-spacing: 0; /* remove border gaps */
    }
    th:first-child {
      border: solid 1px black;
    }
    th {
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-top: solid 1px black;
    }
    td {
      border-right: solid 1px black;
      border-bottom: solid 1px black;
    }
    td:first-child {
      border-left: solid 1px black;
    }
    /* thead{
      display: block;
     min-height: 15mm !important;
    } */
  } 
/* .no-print { display: none; } */